.content {
  color: black;
}

.wrapper {
  background-color: white;
}

.announcementBar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #1c1e21;
  color: black;
  height: auto;

  --site-announcement-bar-stripe-color1: rgb(195 201 181 / 0.75);
  --site-announcement-bar-stripe-color2: rgb(255 188 133 / 0.75);
  background: repeating-linear-gradient(
    35deg,
    var(--site-announcement-bar-stripe-color1),
    var(--site-announcement-bar-stripe-color1) 20px,
    var(--site-announcement-bar-stripe-color2) 10px,
    var(--site-announcement-bar-stripe-color2) 40px
  );
  font-size: 20px;
  font-weight: 700;

  @media (min-width: $mantine-breakpoint-xs) {
    height: 30px;
  }
}

.announcementBarContent {
  flex: auto;
  text-align: center;
  padding: 5px 0;
  font-size: 85%;
}

.closeButton {
  cursor: pointer;
  background: 0 0;
  border: none;

  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.08, 0.52, 0.52, 1);

  padding: 0;
  line-height: 0;

  flex: 0 0 30px;
  align-self: stretch;

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: $mantine-breakpoint-xs) {
    flex-basis: 50px;
  }
}
