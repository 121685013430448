.modal {
  z-index: var(--mantine-z-index-popover);
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xl);
}

.switchBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.switchLabel {
  padding-inline-start: unset;
}
