.AnnouncementBar_content__S5CJQ {
  color: black;
}

.AnnouncementBar_wrapper__OQ3e_ {
  background-color: white;
}

.AnnouncementBar_announcementBar___hi_6 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #1c1e21;
  color: black;
  height: auto;

  --site-announcement-bar-stripe-color1: rgb(195 201 181 / 0.75);
  --site-announcement-bar-stripe-color2: rgb(255 188 133 / 0.75);
  background: repeating-linear-gradient(
    35deg,
    var(--site-announcement-bar-stripe-color1),
    var(--site-announcement-bar-stripe-color1) 20px,
    var(--site-announcement-bar-stripe-color2) 10px,
    var(--site-announcement-bar-stripe-color2) 40px
  );
  font-size: 20px;
  font-weight: 700;
}

@media (min-width: 36em) {

.AnnouncementBar_announcementBar___hi_6 {
    height: 30px
}
  }

.AnnouncementBar_announcementBarContent__LcsRw {
  flex: auto;
  text-align: center;
  padding: 5px 0;
  font-size: 85%;
}

.AnnouncementBar_closeButton__HxXyg {
  cursor: pointer;
  background: 0 0;
  border: none;

  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.08, 0.52, 0.52, 1);

  padding: 0;
  line-height: 0;

  flex: 0 0 30px;
  align-self: stretch;
}

.AnnouncementBar_closeButton__HxXyg:hover {
    opacity: 0.7;
  }

@media (min-width: 36em) {

.AnnouncementBar_closeButton__HxXyg {
    flex-basis: 50px
}
  }

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: var(--mantine-color-background);
}

.CookieDisclaimer_outer__IWI7C {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  font-size: var(--mantine-font-size-sm);
  z-index: var(--mantine-z-index-popover);
  width: 100%;
  color: var(--mantine-color-text);
}

.CookieDisclaimer_inner__Q0iaM {
  border: 1px solid #ffdfc622;
  background-color: var(--mantine-color-dark-6);
  padding: 1rem;
  border-radius: var(--mantine-radius-md);
  max-width: var(--mantine-breakpoint-xs);
  margin: 0.25rem;
  filter: var(--mantine-shadow-lg);
}

@media (min-width: 48em) {

.CookieDisclaimer_inner__Q0iaM {
    margin: 1rem
}
  }

.CookieDisclaimer_content__UYmVz {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--mantine-spacing-xs);
}

.CookieDisclaimer_actions__w1wAf {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.CookieDisclaimer_closeButton__rdlBS:hover {
  background-color: var(--mantine-color-dark-5);
}

.CookieSettings_modal__uvq6N {
  z-index: var(--mantine-z-index-popover);
}

.CookieSettings_modalBody__0s0y1 {
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xl);
}

.CookieSettings_switchBody__j48bF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.CookieSettings_switchLabel__bnJ2b {
  padding-inline-start: unset;
}

.NavbarLinksGroup_control__opHge {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
}

  @media (hover: hover) {

  .NavbarLinksGroup_control__opHge:hover {
    background-color: var(--mantine-color-dark-8);
    color: var(--mantine-color-white);
  }
}

  @media (hover: none) {

  .NavbarLinksGroup_control__opHge:active {
    background-color: var(--mantine-color-dark-8);
    color: var(--mantine-color-white);
  }
}

.NavbarLinksGroup_controlGroup__X84kt {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
}

.NavbarLinksGroup_linkLabel__cGlKP {
  text-decoration: none;
  margin-left: var(--mantine-spacing-md);
  font-weight: 500;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-text);
}

.NavbarLinksGroup_subLink__H85IY {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding-top: var(--mantine-spacing-sm);
  padding-bottom: var(--mantine-spacing-sm);
  padding-left: var(--mantine-spacing-md);
  margin-left: calc(var(--mantine-spacing-lg) + var(--mantine-spacing-md));
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-text);
  border-left: 1px solid var(--mantine-color-dark-4);
}

@media (hover: hover) {

  .NavbarLinksGroup_subLink__H85IY:hover {
    background-color: var(--mantine-color-dark-8);
  }
}

@media (hover: none) {

  .NavbarLinksGroup_subLink__H85IY:active {
    background-color: var(--mantine-color-dark-8);
  }
}

.NavbarLinksGroup_chevron__ANx40 {
  transition: transform 300ms ease;
}

.NavbarLinksGroup_logo__6vTLA {
  height: 50px;
}

@media (min-width: 36em) {

.NavbarLinksGroup_logo__6vTLA {
    height: 40px
}
  }

.Logo_logo__NU4Nk {
  width: auto;
}

.BurgerMenu_drawerContent__7kI3w.BurgerMenu_drawerContent__7kI3w {
  z-index: var(--mantine-z-index-max);
}

@media (min-width: 36em) {

.BurgerMenu_closeButton__9BGj5 {
    display: none
}
  }

.Header_smallDisplayOnly__y8faw {
  display: none;
}

  @media (max-width: 36em) {.Header_smallDisplayOnly__y8faw {
    display: initial
}
  }

.Header_bigDisplayOnly__cXzVP {
  display: initial;
}

@media (max-width: 36em) {

.Header_bigDisplayOnly__cXzVP {
    display: none
}
  }

.Header_outer__zB4dv {
  width: 100%;
  background-color: var(--mantine-color-background);
  position: sticky;
  top: 0;
  /* Home page hero image effect overlay - 200
     Menu pop-up - 300
     So this has to be in-between the two
  */
  z-index: calc(var(--mantine-z-index-popover) - 1);
  height: var(--header-height);
  transition: translate 400ms ease;
}

.Header_outerBoxShadow__jFHJf {
  box-shadow: var(--mantine-shadow-xl);
}

.Header_inner__BWOOt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  max-width: var(--mantine-breakpoint-xl);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: calc(var(--mantine-spacing-xl) * 2 + var(--mantine-breakpoint-xl))) {

.Header_inner__BWOOt {
    padding-left: calc(
      var(--mantine-spacing-xl) - ((100vw - var(--mantine-breakpoint-xl)) / 2)
    );
    padding-right: calc(
      var(--mantine-spacing-xl) - ((100vw - var(--mantine-breakpoint-xl)) / 2)
    )
}
  }

@media (max-width: 88em) {

.Header_inner__BWOOt {
    padding-left: var(--mantine-spacing-xl);
    padding-right: var(--mantine-spacing-xl)
}
  }

.Header_linkGroup__k2DJp {
  display: flex;
}

.Header_link___GWEF {
  display: block;
  line-height: 1rem;
  padding: 8px 12px !important;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-dark-0) !important;
  font-size: var(--mantine-font-size-sm) !important;
  font-weight: 500;
}

.Header_link___GWEF:hover {
    background-color: var(--mantine-color-dark-4);
  }

.Header_subLink__YCpHz {
  text-decoration: none;
  color: var(--mantine-color-dark-0);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}

.Header_linkLabel__Vevr_ {
  margin-right: 5px;
}

.Header_switch__DZp_D {
  background-color: var(--mantine-color-dark-5);
  color: var(--mantine-primary-color);
  border: none;
  border-radius: var(----mantine-radius-md);
}

.Header_switch__DZp_D:hover {
    background-color: var(--mantine-color-dark-4);
  }

.Footer_footer__o4_Ec {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  color: var(--mantine-color-gray-6);
}

.Footer_logo__ZNv_V {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer_description___v0wZ {
  margin-top: var(--mantine-spacing-xs) !important;
  text-align: center;
}

.Footer_inner__w0_wv {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  max-width: var(--mantine-breakpoint-md);
  margin-left: auto;
  margin-right: auto;
}

.Footer_wrapper__znyEu {
  width: 10rem;
}

.Footer_afterFooter__9Bc_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--mantine-color-dark-4);
  gap: var(--mantine-spacing-md);

  margin: var(--mantine-spacing-xl) auto 0;
  padding: var(--mantine-spacing-xl) var(--mantine-spacing-md);
  max-width: var(--mantine-breakpoint-md);
}

@media (max-width: 48em) {

.Footer_afterFooter__9Bc_b {
    flex-direction: column
}
  }

@media (max-width: 62em) {

.Footer_social__ZBgR2 {
    margin-top: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-xl)
}
  }

@media (max-width: 62em) {

.Footer_socialDivider__LJWo2 {
    margin-left: calc(var(--mantine-spacing-xs) * -1);
    margin-right: calc(var(--mantine-spacing-xs) * -1)
}
  }

.Footer_actionIcon__61oh9.Footer_actionIcon__61oh9 {
  --ai-size: var(--ai-size-lg);
}

@media (min-width: 48em) {

.Footer_actionIcon__61oh9.Footer_actionIcon__61oh9 {
    --ai-size: var(--ai-size-md)
}
  }

.Footer_icon__LpgAQ {
  color: var(--mantine-primary-color);
  width: 100%;
  height: 100%;
}

