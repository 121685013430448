.footer {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  color: var(--mantine-color-gray-6);
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  margin-top: var(--mantine-spacing-xs) !important;
  text-align: center;
}

.inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  max-width: var(--mantine-breakpoint-md);
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  width: 10rem;
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--mantine-color-dark-4);
  gap: var(--mantine-spacing-md);

  margin: var(--mantine-spacing-xl) auto 0;
  padding: var(--mantine-spacing-xl) var(--mantine-spacing-md);
  max-width: var(--mantine-breakpoint-md);

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: $mantine-breakpoint-md) {
    margin-top: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-xl);
  }
}

.socialDivider {
  @media (max-width: $mantine-breakpoint-md) {
    margin-left: calc(var(--mantine-spacing-xs) * -1);
    margin-right: calc(var(--mantine-spacing-xs) * -1);
  }
}

.actionIcon.actionIcon {
  --ai-size: var(--ai-size-lg);

  @media (min-width: $mantine-breakpoint-sm) {
    --ai-size: var(--ai-size-md);
  }
}

.icon {
  color: var(--mantine-primary-color);
  width: 100%;
  height: 100%;
}
