.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);

  @mixin hover {
    background-color: var(--mantine-color-dark-8);
    color: var(--mantine-color-white);
  }
}

.controlGroup {
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
}

.linkLabel {
  text-decoration: none;
  margin-left: var(--mantine-spacing-md);
  font-weight: 500;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-text);
}

.subLink {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding-top: var(--mantine-spacing-sm);
  padding-bottom: var(--mantine-spacing-sm);
  padding-left: var(--mantine-spacing-md);
  margin-left: calc(var(--mantine-spacing-lg) + var(--mantine-spacing-md));
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-text);
  border-left: 1px solid var(--mantine-color-dark-4);

  @mixin hover {
    background-color: var(--mantine-color-dark-8);
  }
}

.chevron {
  transition: transform 300ms ease;
}

.logo {
  height: 50px;

  @media (min-width: $mantine-breakpoint-xs) {
    height: 40px;
  }
}
